import { useEffect, useState } from "react"

// packages
import { Flex, Form, Space } from "antd"
import dayjs from "dayjs"
import { t } from "i18next"
import { useQuery } from "react-query"

// components | common
import {
  AccordionCard,
  Box,
  Button,
  DatePicker,
  SelectInput,
  filterObject,
  setItemToLocalstorage,
  useUpdateSearchParams,
} from "@project/common"
import { OperationFields } from "../ServiceProvisionRecord/common/OperationFields"

// styles
import { AdditionalItemMgmtContainer } from "./styles"

// context
import { useAuthContext } from "../../context"

// services
import { getAllUsers } from "../../services/additionalItems"

// utils
import { getFuriFilteredOptions } from "../../utils"
import { FuriganaAlphabetsOption } from "../../utils/common-options"

// types
import { ItemMgmtFormDataType } from "./types"

// constants
import _ from "lodash"
import { useRouter } from "next/router"
import { MONTH_DATE_FORMAT } from "../../constants"
import { Facilities } from "../../types/globalFacilities"

export const ItemMgmtOperation = ({
  memoizedQuery,
}: {
  memoizedQuery: ItemMgmtFormDataType
}) => {
  const router = useRouter()
  const { facility_id } = router.query
  const { facilities } = useAuthContext()
  const [facility, setFacility] = useState(facility_id || facilities[0]?.value)
  const [date, setDate] = useState({
    year: dayjs().year(),
    month: dayjs().month() + 1,
  })
  const [form] = Form.useForm<ItemMgmtFormDataType>()
  const furiValue = Form.useWatch("furigana", form)
  const yearMonthValue = Form.useWatch("year_month", form)

  const [updateParams] = useUpdateSearchParams()

  // 1. Fetching all users
  const { data: users, isLoading: loadingUsers } = useQuery(
    ["users", facility, date],
    () => getAllUsers(facility, date),
    {
      select: (users) =>
        users.data.map((user) => ({
          id: user.id,
          value: user.id,
          label: user.username,
          furigana: user.furigana,
        })),
    },
  )

  // 1. filter operation form submission event
  const onFinish = (values: ItemMgmtFormDataType) => {
    const data = filterObject({
      ...values,
      year_month: values.year_month.format("YYYY-MM"),
    })
    setItemToLocalstorage("additional_item_adult", data)
    updateParams(data, "/additional-items-management/adult")
  }

  // 2. Filter operation form reset event
  const onReset = () => {
    updateParams({}, "/additional-items-management/adult")
    form.setFieldsValue({
      user_id: null,
      facility_id: String(_.head(facilities as Facilities).value),
      year_month: dayjs(),
    })
    setDate({
      year: dayjs().year(),
      month: dayjs().month() + 1,
    })
    setFacility(facilities[0]?.value)
  }

  // 1. ReInitializing year_month value if the memoizedQuery's year_month updates
  useEffect(() => {
    if (memoizedQuery?.year_month) {
      form.setFieldValue("year_month", memoizedQuery?.year_month)
    }
  }, [memoizedQuery?.year_month])

  return (
    <AccordionCard
      defaultActiveKey={["service-provision-record"]}
      items={[
        {
          key: "service-provision-record",
          label: t("Operation Options"),
          children: (
            <Form
              form={form}
              onFinish={onFinish}
              name={"control-hooks"}
              initialValues={memoizedQuery}
            >
              <AdditionalItemMgmtContainer
                display={"flex"}
                direction={"column"}
                gap={"16px"}
              >
                <OperationFields
                  label={"Facility"}
                  align={"center"}
                  labelWidth={"150px"}
                  wrap={"wrap"}
                  gap={"10px 24px"}
                >
                  <Box w={208} maw={208} miw={208} className={"input-field"}>
                    <Form.Item name={"facility_id"} id={"facility_id"}>
                      <SelectInput
                        name={"facility_id"}
                        id={"facility_id"}
                        options={facilities}
                        placeholder={"---"}
                        onChange={(e) => {
                          setFacility(e)
                          form.setFieldsValue({
                            user_id: null,
                          })
                        }}
                      />
                    </Form.Item>
                  </Box>
                </OperationFields>

                <OperationFields
                  label={"Year month"}
                  align={"center"}
                  labelWidth={"150px"}
                  wrap={"wrap"}
                  gap={"10px 24px"}
                >
                  <Box w={208} maw={208} miw={208} className={"input-field"}>
                    <Form.Item name={"year_month"} id={"year_month"}>
                      <DatePicker
                        picker={"month"}
                        id={"year_month"}
                        date={yearMonthValue}
                        onDateChange={(date) => {
                          form.setFieldValue("year_month", date)
                          setDate({
                            year: date.year(),
                            month: date.month() + 1,
                          })
                          form.setFieldsValue({
                            user_id: null,
                          })
                        }}
                        format={MONTH_DATE_FORMAT}
                      />
                    </Form.Item>
                  </Box>
                </OperationFields>

                <OperationFields
                  label={"User name"}
                  align={"center"}
                  labelWidth={"150px"}
                  wrap={"wrap"}
                  gap={"10px 24px"}
                >
                  <Flex gap={16}>
                    <Box w={80} maw={80} miw={80} className={"input-field"}>
                      <Form.Item name={"furigana"} id={"furigana"}>
                        <SelectInput
                          name={"furigana"}
                          id={"furigana"}
                          options={FuriganaAlphabetsOption}
                          placeholder={"--"}
                          onChange={(val) =>
                            form.setFieldsValue({
                              user_id: null,
                              furigana: val,
                            })
                          }
                        />
                      </Form.Item>
                    </Box>
                    <Box w={200} maw={307} miw={200} className={"input-field"}>
                      <Form.Item name={"user_id"} id={"user_id"}>
                        <SelectInput
                          name={"user_id"}
                          id={"user_id"}
                          loading={loadingUsers}
                          disabled={loadingUsers}
                          options={getFuriFilteredOptions(users, furiValue)}
                          placeholder={"---"}
                        />
                      </Form.Item>
                    </Box>
                  </Flex>
                </OperationFields>

                <Space size={12}>
                  <Button
                    shape={"round"}
                    btnText={t("Reset search")}
                    onClick={() => onReset()}
                  />
                  <Button
                    shape={"round"}
                    type={"primary"}
                    iconType={"search"}
                    btnText={t("Search")}
                    htmlType={"submit"}
                  />
                </Space>
              </AdditionalItemMgmtContainer>
            </Form>
          ),
        },
      ]}
    />
  )
}
