import router, { useRouter } from "next/router"
import React, { useMemo } from "react"

// packages
import { Flex, List } from "antd"
import dayjs from "dayjs"
import { t } from "i18next"
import _ from "lodash"
import { useQuery } from "react-query"

// commons | components
import {
  Box,
  BusinessCalendarPagination,
  Button,
  Calendar,
  Card,
  filterObject,
  getLocalstorageItem,
  keys,
  MonthPagination,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"
import { ItemMgmtOperation } from "./ItemMgmtOperation"

// styles
import { DailyAdditionCalendarItem, ItemMgmtCalendarContainer } from "./styles"

// services
import { getAllAdditionalItem } from "../../services"

// contexts
import { useAuthContext } from "../../context"

// types
import { Facilities } from "../../types/globalFacilities"
import {
  AdditionalItemUserPermission,
  hasPermissionForFacility,
} from "../../utils/PermissionKeys"
import { AdditionalItemResp, ItemMgmtFormDataType } from "./types"

export const AdditionalItemMgmtInternal = () => {
  const today = dayjs()
  const { query } = useRouter()

  const { facilities, isOwner, permissions } = useAuthContext()
  const { showToast } = useNotification()

  const [updateParam] = useUpdateSearchParams()
  const userId = +query?.user_id || null

  const memoizedQuery: ItemMgmtFormDataType = React.useMemo(() => {
    const storedData = getLocalstorageItem("additional_item_adult")
    const user_id = userId
    const facility_id =
      storedData?.facility_id?.toString() ||
      query?.facility_id?.toString() ||
      String(_.head(facilities as Facilities).value)
    const year_month = storedData?.year_month
      ? dayjs(storedData?.year_month)
      : query?.year_month
        ? dayjs(query?.year_month?.toString())
        : today

    return {
      user_id,
      facility_id,
      year_month,
    }
  }, [query])

  const { data: allAdditionalItem } = useQuery(
    ["all-additional-item", memoizedQuery],
    () =>
      getAllAdditionalItem({
        facility: memoizedQuery?.facility_id,
        month: memoizedQuery?.year_month.month() + 1 + "",
        year: memoizedQuery?.year_month.year() + "",
        user_id: memoizedQuery?.user_id,
      }),
    {
      select: (resp) => resp.data,
      onError: () => {
        showToast({
          message: t("Something went wrong. Please contact administrator."),
          type: "error",
        })
      },
    },
  )

  const hasEditAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        AdditionalItemUserPermission,
        "write",
        memoizedQuery.facility_id,
      ),
    [isOwner, permissions, memoizedQuery],
  )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <ItemMgmtOperation memoizedQuery={memoizedQuery} />

      <ItemMgmtCalendarContainer>
        <Card
          title={t("{{facilityName}} {{date}}’s daily addition items", {
            facilityName: facilities?.find(
              (fac) => fac?.value == memoizedQuery.facility_id,
            )?.facility_name,
            date: memoizedQuery?.year_month?.format("YYYY年MM月"),
          })}
          extra={
            //  Pagination no. 1
            <MonthPagination
              showDate={false}
              currentMonth={memoizedQuery.year_month.month()}
              currentYear={memoizedQuery.year_month.year()}
              onMonthChange={(__, _, date) =>
                updateParam(
                  filterObject({
                    ...memoizedQuery,
                    year_month: date.format("YYYY-MM"),
                  }),
                  "/additional-items-management/adult",
                )
              }
            />
          }
        >
          <Calendar
            className={"additional-item-mgmt-calendar"}
            value={memoizedQuery.year_month}
            type={"SECONDARY"}
            minWidth={"800px"}
            cellRender={(date) =>
              dailyAdditionCalendarContent({
                currentDate: date,
                memoizedQuery: memoizedQuery,
                allAdditionalItem: allAdditionalItem,
                hasEditAccess,
              })
            }
          />

          {/* Pagination no. 2 */}
          <Flex justify={"flex-end"}>
            <BusinessCalendarPagination
              currentMonth={memoizedQuery.year_month.month() + 1}
              currentYear={memoizedQuery.year_month.year()}
              handleMonthChange={(_, month) =>
                updateParam(
                  filterObject({
                    ...memoizedQuery,
                    year_month: dayjs(
                      `${memoizedQuery.year_month.year()}-${month}`,
                    ).format("YYYY-MM"),
                  }),
                  "/additional-items-management/adult",
                )
              }
              handleYearChange={(_, year) =>
                updateParam(
                  filterObject({
                    ...memoizedQuery,
                    year_month: dayjs(
                      `${year}-${memoizedQuery.year_month.month()}`,
                    ).format("YYYY-MM"),
                  }),
                  "/additional-items-management/adult",
                )
              }
            />
          </Flex>
        </Card>
      </ItemMgmtCalendarContainer>
    </div>
  )
}

export const dailyAdditionCalendarContent = ({
  allAdditionalItem,
  currentDate,
  memoizedQuery,
  hasEditAccess,
}: {
  allAdditionalItem?: AdditionalItemResp
  currentDate: dayjs.Dayjs
  memoizedQuery: ItemMgmtFormDataType
  hasEditAccess: boolean
}) => {
  const date = dayjs(currentDate).format("YYYY-MM-DD")
  const additionalItem = allAdditionalItem?.additional_items[date]

  return {
    isHoliday: allAdditionalItem?.holidays?.includes(date),
    content: (
      <DailyAdditionCalendarItem
        mah={"max-content"}
        mih={100}
        p={12}
        w={"100%"}
        display={"flex"}
        align={"flex-start"}
        justify={"flex-end"}
        direction={"column"}
        gap={"sm"}
        ta={"start"}
      >
        {additionalItem && (
          <List
            style={{
              width: "100%",
            }}
            itemLayout={"horizontal"}
            dataSource={keys(additionalItem)}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <div>
                      {"- "}
                      {t(item)}
                    </div>
                  }
                  description={additionalItem[item].map((val) => (
                    <Box ml={"8px"} key={val.user_id}>
                      {val.user_info?.username}
                    </Box>
                  ))}
                />
              </List.Item>
            )}
          />
        )}
        <Button
          className={"edit-btn"}
          btnText={t("edit")}
          shape={"round"}
          type={"faded"}
          onClick={() =>
            router.push(
              `/additional-items-management/bulk-edit/${memoizedQuery?.facility_id}?date=${date}&user_id=${memoizedQuery?.user_id}`,
            )
          }
          disabled={!hasEditAccess}
        />
      </DailyAdditionCalendarItem>
    ),
  }
}
