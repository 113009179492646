import { API } from "@project/common"
export interface IReturnMunicipalSubsidy {
  count?: number
  data?: any
}
const BaseURL = "additional_items"
export const getAdditionalItems = async ({ id, date, user_id }) => {
  return API.get(`/${BaseURL}?facility=${id}`, { params: { date, user_id } })
}
export const postAdditionalItems = async (data) => {
  return API.post(`/${BaseURL}`, data)
}
export const getAllUsers = async (facility, date) => {
  return API.get(
    `/user?page=1&pageSize=Infinity&facilityIds=${facility}&year=${date.year}&month=${date.month}`,
  )
}

export const getAdditionalItemsFacility = async ({ facility, date }) => {
  return API.get(`/additional_items_facilities`, {
    params: { facility, date },
  })
}

export const postAdditionalItemsFacility = async (data) => {
  return API.post(`/additional_items_facilities`, data)
}

export const getMonthlyAdditionalItemsFacility = async ({
  facility,
  year,
  month,
}) => {
  return API.get(`/additional_items_facilities`, {
    params: { facility, year, month },
  })
}
