import styled from "styled-components"
import { Box, theme } from "@project/common"

export const AdditionalItemMgmtContainer = styled(Box)`
  .ant-form-item {
    margin-bottom: 0;
  }
`

export const DailyAdditionCalendarItem = styled(Box)`
  button.edit-btn {
    height: 30px !important;
    min-height: 30px !important;
    font-size: 14px;
    color: ${theme.colors.text};
  }
`

export const ItemMgmtCalendarContainer = styled.div`
  .additional-item-mgmt-calendar {
    padding: 20px;
    border: 1px solid ${theme.colors.border};
    border-radius: 8px;
    margin-bottom: 24px;
  }
`
